import { useEffect, useState } from 'react';
import axios from 'axios';
import {tokenStorage} from "../utils/tokenUtil";

const BASE_URL = `${process.env.REACT_APP_API_URL}`;
const token = tokenStorage();
const axiosInstanceDefault = axios.create({
	baseURL: BASE_URL,
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		...token && {'Authorization': `Bearer ${token?.token}`},
		...token && {'Refresh-Token': `${token?.refreshToken}`},
	},
});

const useApi = () => {
	const [response, setResponse] = useState([]);
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [controller, setController] = useState();

	const axiosFetch = async (configObj) => {
		const {
			axiosInstance = axiosInstanceDefault,
			method,
			url,
			requestConfig = {}
		} = configObj;

		try {
			setLoading(true);
			const ctrl = new AbortController();
			setController(ctrl);
			const res = await axiosInstance[method.toLowerCase()](url, {
				...requestConfig,
				signal: ctrl.signal,
			});
			if(res.data?.data?.token) tokenStorage(res.data?.data?.token, res.data?.data?.refreshToken);
			if(res.data?.data?.refreshToken) tokenStorage(res.data?.data?.token, res.data?.data?.refreshToken);
			res.data.statusCode === 200 ? setResponse(res.data?.data) : setError(res.data?.message);

			return res.data;
		} catch (err) {
			setError(err.message);
			return err.response?.data;
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		return () => controller && controller.abort();
	}, [controller]);

	return [response, error, loading, axiosFetch];
};

export default useApi;
