export const textColor = (color, shade) => {
	if (typeof color === 'undefined') return null;
	if (['inherit', 'current', 'transparent', 'black', 'white'].includes(color)) {
		return `text-${color}`;
	}
	if (typeof shade === 'undefined') return `text-${color}-500`;
	return `text-${color}-${shade}`;
};

const textColorUtil = (
	color,
	shade,
	opacity,
	cond,
) => {
	const value = textColor(color, shade);
	const conditional = typeof cond === 'string' ? cond : '';

	if (opacity) return `${conditional}${value}/${opacity}`;
	return `${conditional}${value}`;
};

export default textColorUtil;
