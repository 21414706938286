import { makeAutoObservable } from 'mobx';
import {jwtDecode} from "jwt-decode";
import systemStore from "./systemStore";
import {createErrorMsgModal} from "../utils/common.util";

class PaymentStore
{
    users = null;
    userToken = this.getToken();
    userPrefix = `${this.users?.usIdx ?? '__tmp'}:pR:`
    reservation = {};
    paymentStep = 'step:1'
    serviceItem = null;

    async setReservationByToken(token) {
        const _reservation = jwtDecode(token);
        const PG_CODE = _reservation?.Shop?.ShDsc?.PG?.code;
        this.reservation = _reservation;
        console.log('Reservation: ', _reservation);
        console.log('PG_CODE: ', PG_CODE);
        if(PG_CODE) window.IMP.init(PG_CODE);
        if(!PG_CODE) createErrorMsgModal(500, 'Hash Key', '잘못된 Hash Key 입니다.');
        systemStore.loadFinish();

        return _reservation;
    }

    setReservation(reservation){
        this.reservation = {
            ...this.reservation,
            ...reservation,
        };
    }



    getToken(){
        try{
            const _users = jwtDecode(localStorage.getItem('token'));
            this.users = _users?.decoded;
            return _users;
        }catch (e) {

        }
    }
    setPaymentResult(merchant_uid, data, start = false){
        if(start) this.deletePaymentResult();
        const _paymentKey = `${this.userPrefix}${merchant_uid}`
        let _data = localStorage.getItem(_paymentKey);
        if(typeof data === 'string') {
            _data = {
                ...( _data ? JSON.parse(_data) : {}), data
            }
        } else {
            _data = {
                ...( _data ? JSON.parse(_data) : {}),
                ...data
            }
        }
        localStorage.setItem(_paymentKey, JSON.stringify(_data));
        return _data;
    }

    getPaymentResult(){
        const dataKey = Object.keys(localStorage).find(key => key.startsWith(this.userPrefix));
        const _data = localStorage.getItem(dataKey);
        return _data ? JSON.parse(_data): {};
    }

    deletePaymentResult(){
        Object.keys(localStorage).map(key => {
            if(key.startsWith(this.userPrefix)){
                localStorage.removeItem(key);
            }
        });
    }

    changePaymentStep(step) {
        console.log('changePaymentStep: ', step);
        this.paymentStep = step;
    }

    setServiceItem(item) {
        console.log('setServiceItem: ', item);
        this.serviceItem = item;
        if(item?.step) this.changePaymentStep(item?.step);
    }


    constructor()
    {
        makeAutoObservable(this);
    }

}

export default new PaymentStore()