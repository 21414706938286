import React, {useEffect} from 'react';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import ContentRouter from './components/router/ContentRouter';
import useFontSize from './hooks/useFontSize';
import getOS from './utils/getOS.util';
import { ContentContextProvider } from './context/contentContext';
import ErrorModal from "./pages/Common/errorModal.page";
import MatrixRain from "./pages/Common/Landing.page";
import SplashScreen from "./components/ui/SplashScreen";

const App = () => {
	console.log('App');
	getOS();

	const { fontSize } = useFontSize();
	dayjs.extend(localizedFormat);

	const notResultPage = window.location.hash !== '#/payment-result'

	useEffect(() => {
		if(process.env.NODE_ENV !== 'development'){
			if ('serviceWorker' in navigator) {
				navigator.serviceWorker.register('/service-worker.js')
					.then(function(registration) {
						console.log('Service Worker 등록 성공:', registration);
					})
					.catch(function(error) {
						console.log('Service Worker 등록 실패:', error);
					});
			}
		}
	}, []);

	return (
		<>
			<MatrixRain />
			{/*{notResultPage && <SplashScreen />}*/}
			{/*<style>{`:root {font-size: ${fontSize}px}`}</style>*/}
			{/*<div data-component-name='App' className='flex grow flex-col'>*/}
			{/*	<ContentContextProvider>*/}
			{/*		<ContentRouter />*/}
			{/*	</ContentContextProvider>*/}
			{/*</div>*/}
			{/*<ErrorModal />*/}
		</>
	);
};

export default App;
