import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import 'dayjs/locale/en';
import 'dayjs/locale/ko';

import themeConfig from './config/theme.config';

import enTranslation from './locales/en/translation.json';
import enMenu from './locales/en/menu.json';
import koTranslation from './locales/ko/translation.json';
import koMenu from './locales/ko/menu.json';

i18n.use(initReactI18next)
	.init({
		resources: {
			ko: {
				translation: koTranslation,
				menu: koMenu,
			},
			en: {
				translation: enTranslation,
				menu: enMenu,
			},
		},
		fallbackLng: themeConfig.language,
		lng: themeConfig.language,
		// debug: true,

		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
	})
	.then();

export default i18n;
