import { useEffect, useRef } from 'react';
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';

function useEventListener(eventName, handler, element, options) {
	// Create a ref that stores handler
	const savedHandler = useRef();

	// Update ref.current value if handler changes.
	// The function is always the same so if it has the same dependencies it will not re-run
	useIsomorphicLayoutEffect(() => {
		savedHandler.current = handler;
	}, [handler]);

	useEffect(() => {
		// Define the listening target
		const targetElement = element?.current || window;

		if (!targetElement.addEventListener) return;

		// Create event listener that calls handler function stored in ref
		const eventListener = (event) => {
			// Check if savedHandler.current is a function, if not, do not call it
			if (typeof savedHandler.current === 'function') {
				savedHandler.current(event);
			}
		};

		targetElement.addEventListener(eventName, eventListener, options);

		// Remove event listener on cleanup
		return () => {
			targetElement.removeEventListener(eventName, eventListener, options);
		};
	}, [eventName, element, options]);
}

export default useEventListener;
