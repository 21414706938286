import React, { createContext, useState, useLayoutEffect, useEffect, useMemo } from 'react';
import theme from 'tailwindcss/defaultTheme';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import DARK_MODE from '../constants/darkMode.constant';
import themeConfig from '../config/theme.config';
import useDeviceScreen from '../hooks/useDeviceScreen';

const ThemeContext = createContext();

export const ThemeContextProvider = ({ children }) => {
	/**
	 * Language
	 */
	const { i18n } = useTranslation();
	const [language, setLanguage] = useState(
		localStorage.getItem('space_language') || themeConfig.language,
	);
	useLayoutEffect(() => {
		localStorage.setItem('space_language', language);

		i18n.changeLanguage(language)
			.then(() => {
				document.documentElement.setAttribute('dir', i18n.dir());
				document.documentElement.setAttribute('lang', i18n.language);
			})
			.catch((error) => console.error(error));

		dayjs.locale(language);
	}, [i18n, language]);

	/**
	 * Dark Mode
	 */
	const [darkModeStatus, setDarkModeStatus] = useState(
		localStorage.getItem('theme') || themeConfig.theme,
	);
	const [isDarkTheme, setIsDarkTheme] = useState(darkModeStatus === DARK_MODE.DARK);
	useLayoutEffect(() => {
		localStorage.setItem('theme', darkModeStatus);

		if (
			localStorage.getItem('theme') === DARK_MODE.DARK ||
			(localStorage.getItem('theme') === DARK_MODE.SYSTEM &&
				window.matchMedia(`(prefers-color-scheme: ${DARK_MODE.DARK})`).matches)
		) {
			document.documentElement.classList.add(DARK_MODE.DARK);
			setIsDarkTheme(true);
		} else {
			document.documentElement.classList.remove(DARK_MODE.DARK);
			setIsDarkTheme(false);
		}
	}, [darkModeStatus]);

	/**
	 * Aside Status
	 */
	const { width } = useDeviceScreen();
	const [asideStatus, setAsideStatus] = useState(
		localStorage.getItem('space_asideStatus') === 'true'
	);
	const [mobileDevice, setMobileDevice] = useState(
		localStorage.getItem('space_mobile') === 'true'
	);
	useLayoutEffect(() => {
		if (parseInt(theme.screens.md.replace('px', ''), 10) <= width)
			localStorage.setItem('space_asideStatus', asideStatus.toString());
	}, [asideStatus, width]);
	useEffect(() => {
		if (parseInt(theme.screens.md.replace('px', ''), 10) > width) setAsideStatus(false);
		return () => {
			setAsideStatus(localStorage.getItem('space_asideStatus') === 'true');
		};
	}, [width]);

	/**
	 * Font Size
	 */
	const [fontSize, setFontSize] = useState(
		parseInt(localStorage.getItem('space_fontSize'), 10) || themeConfig.fontSize,
	);
	useLayoutEffect(() => {
		localStorage.setItem('space_fontSize', fontSize.toString());
	}, [fontSize]);

	const isMobileDevice = () => {
		return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	};

	useEffect(() => {
		localStorage.setItem('space_mobile', isMobileDevice().toString());
	}, []);

	const values = useMemo(() => ({
		isDarkTheme,
		darkModeStatus,
		setDarkModeStatus,
		asideStatus,
		setAsideStatus,
		fontSize,
		setFontSize,
		language,
		setLanguage,
		mobileDevice
	}), [isDarkTheme, darkModeStatus, asideStatus, fontSize, language]);

	return <ThemeContext.Provider value={values}>{children}</ThemeContext.Provider>;
};

export default ThemeContext;
