import React, { useState } from 'react';
import classNames from 'classnames';


import themeConfig from '../../config/theme.config';

import Icon from '../icon/Icon';

import useColorIntensity from '../../hooks/useColorIntensity';


import CloseButton from './CloseButton';
const Alert = (props) => {
	const {
		borderWidth,
		children,
		className,
		color,
		colorIntensity,
		icon,
		iconSize,
		isClosable,
		rounded,
		title,
		variant,
		...rest
	} = props;
	const [status, setStatus] = useState(true);

	const { textColor } = useColorIntensity(colorIntensity);

	const badgeVariants = {
		solid: classNames(
			[`${textColor}`],
			[`bg-${color}-${colorIntensity}`],
			'border-transparent',
		),
		outline: classNames(
			[`border-${color}-${colorIntensity}`],
			[`bg-${color}-${colorIntensity}/10`],
			[`text-${color}-${colorIntensity}`],
		),
		default: classNames(
			[`text-${color}-${colorIntensity}`],
			'border-transparent',
		),
	};
	const badgeVariantClasses = badgeVariants[variant];

	const classes = classNames(
		'relative',
		'flex h-fit items-stretch',
		'p-4',
		[`${borderWidth}`],
		[`${rounded}`],
		badgeVariantClasses,
		className,
	);

	if (status)
		return (
			<div data-component-name='Alert' className={classes} {...rest}>
				{icon && (
					<Icon
						icon={icon}
						className='shrink-0 self-center ltr:mr-4 rtl:ml-4 text-red-600'
						size={iconSize}
					/>
				)}
				<div className='w-full grow self-center'>
					{title && <div className='text-lg font-semibold text-light-primaryText dark:text-dark-primaryText'>{title}</div>}
					<div>{children}</div>
				</div>
				{isClosable && (
					<div className='flex h-full grow items-start'>
						<CloseButton setIsOpen={setStatus} />
					</div>
				)}
			</div>
		);
	return null;
};
Alert.defaultProps = {
	borderWidth: themeConfig.borderWidth,
	className: undefined,
	color: themeConfig.themeColor,
	colorIntensity: themeConfig.themeColorShade,
	icon: undefined,
	iconSize: 'text-3xl',
	isClosable: false,
	rounded: themeConfig.rounded,
	title: undefined,
	variant: 'default',
};
Alert.displayName = 'Alert';

export default Alert;
