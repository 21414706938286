import { v4 as uuidv4 } from 'uuid';
import SOLO from '../constants/solo.constant';
import {indexOf} from "lodash";
const saveDataAsBytes = (key,data) => {
    const byteArray = data;
    const saveData = (typeof byteArray === 'object')?JSON.stringify(byteArray):byteArray;
    localStorage.setItem(key, saveData);
}

const loadDataAsBytes = (key) => {
    let bytes = localStorage.getItem(key);
    try{
        bytes = JSON.parse(bytes);
        return bytes;
    }catch (e) {
        return bytes;
    }
}

export const LocStore = (key, value = null) => {
    if (value) {
        value = (typeof value === 'object') ? JSON.stringify(value) : value;
        saveDataAsBytes(key, value)
        return true;
    } else {
        const _val = loadDataAsBytes(key);
        try {
            return _val??undefined;
        } catch (e) {
            if (_val??''.indexOf('object Object') > -1) {
                localStorage.removeItem(key);
                return null;
            }
            return _val;
        }
    }
}

export const RmStore = (key, search=true) => {
    try {
        if(search){
            const _localKeys = Object.keys(localStorage);
            const _keys = _.filter(_localKeys, (item1) => {
                return _.some(key, (item2) => {
                    return item1.includes(item2);
                });
            });
            _keys.map(_key => {
                console.log('_key: ', _key);
                localStorage.removeItem(_key);
            });
            return true;
        }

        if(_.isArray(key)){
            key.map(_key => localStorage.removeItem(_key));
            return true;
        } else if(indexOf(key, '*') > -1){
            const _keys = Object.keys(localStorage).filter(_key => _key.indexOf(key.replace('*','')) > -1);
            _keys.map(_key => localStorage.removeItem(_key));
            return true;
        }else{
            localStorage.removeItem(key)
        }

    } catch (e) {

    }
}

export const LocDeviceToken = (hashKey) => {
    const _tokenKey = `${SOLO.DEVICE_TOKEN}${hashKey}`;
    const _token = LocStore(_tokenKey);
    if(!_token){
        const _newToken = uuidv4();
        LocStore(_tokenKey, _newToken);
        return _newToken;
    }
    return _token;
}