import React, {createContext, useEffect, useMemo, useState} from 'react';
import {io} from "socket.io-client";
import {LocDeviceToken, LocStore, RmStore} from "../utils/localStorage.util";
import paymentStore from "../store/paymentStore";
import SOLO from "../constants/solo.constant";
import systemStore from "../store/systemStore";
// import {createErrorMsgModal} from "../utils/common.util";

const SoloContext = createContext();

export const SoloProvider = ({children}) => {
    const [userData, setUserData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    async function fetchYourAPI(imp_uid, merchant_uid, imp_success) {
        return fetch(`${process.env?.REACT_APP_API_URL}/payment/deposit/result?imp_uid=${imp_uid}&merchant_uid=${merchant_uid}&imp_success=${imp_success}`)
            .then(response => response.json());
    }

    const checkSoloUrl = () => {
        const _params = window.location.pathname.split('/');
        const _hashSoloKey = _params?.[_params.length - 1];

        const queryParams = new URLSearchParams(window.location.search);
        const imp_uid = queryParams.get('imp_uid');
        const merchant_uid = queryParams.get('merchant_uid');
        const imp_success = queryParams.get('imp_success');

        return new Promise((resolve, reject) => {
            if (!_hashSoloKey) reject('no solo key');
            if (imp_uid && merchant_uid && imp_success) {
                fetchYourAPI(imp_uid, merchant_uid, imp_success)
                    .then(response => {
                        paymentStore.setPaymentResult(merchant_uid, response?.data);
                        console.log('_cacheData: ', response);
                        defaultLandingUrl();
                    })
                    .catch(error => console.error(error));
            }else if(_hashSoloKey){
                console.log('_hashSoloKey: ', _hashSoloKey);
                const _oldHashKey = LocStore(SOLO.HASH_KEY);
                if (_oldHashKey !== _hashSoloKey) {
                    RmStore(Object.values(SOLO));
                }
                LocStore(`${SOLO.HASH_KEY}`, _hashSoloKey);
                LocStore(`${SOLO.LANDING_URL}${_hashSoloKey}`, window.location.href);
                if (_hashSoloKey) {
                    resolve(_hashSoloKey)
                } else {
                    reject('no solo key')
                }
            }
        })
    }


    const defaultLandingUrl = () => {
        const defaultUrl = LocStore(`${SOLO.LANDING_URL}${LocStore(SOLO.HASH_KEY)}`);
        console.log('defaultUrl: ', defaultUrl);
        if (defaultUrl) {
            window.location.href = defaultUrl;
        }
    }

    const connectSoloSocket = (device, {clientAuth, hashKey}) => {
        return new Promise((resolve, reject) => {
            try {
                const _socket = io(`${process.env.REACT_APP_SOCKET_URL || 'http://localhost:4000'}`, {
                    query: {
                        clientAuth,
                        soloKey: hashKey,
                    }
                });
                resolve(_socket)
            } catch (e) {
                reject(e)
            }
        })
    }


    useEffect(() => {
        checkSoloUrl().then(async (hashKey) => {
            const clientAuth = LocDeviceToken(hashKey)
            const _userData = LocStore(`${SOLO.CLIENT_AUTH}${hashKey}`);
            // let connectionCnt = 0;

            const io = await connectSoloSocket(
                _userData,
                {
                    clientAuth,
                    hashKey,
                }
            );

            io.on('outUserLogin', (res) => {
                systemStore.showErrorCustomModal(res.message);
            });

            // io.on('connect_error', async () => {
            //     connectionCnt++
            //     if(connectionCnt > 3) {
            //         createErrorMsgModal(606, 'Socket', '소켓 연결 실패');
            //     }
            // })
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    const value = useMemo(() => ({
        userData,
        isLoading,
    }), [userData, isLoading]);

    return <SoloContext.Provider value={value}>{children}</SoloContext.Provider>;
};
