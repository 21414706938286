import DARK_MODE from '../constants/darkMode.constant';

const themeConfig = {
	projectTitle: '예약',
	projectName: '스페이스포스 예약',
	language: 'ko',
	theme: DARK_MODE.SYSTEM, // Assuming DARK_MODE.SYSTEM is a constant from the imported module
	themeColor: 'blue',
	themeColorShade: '500',
	rounded: 'rounded-lg',
	borderWidth: 'border-2',
	transition: 'transition-all duration-300 ease-in-out',
	fontSize: 13,
};

export default themeConfig;

