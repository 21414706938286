import ReactDOM from 'react-dom';

const Portal = ({ id, children }) => {
	const mount = document.getElementById(id);
	if (mount) return ReactDOM.createPortal(children, mount);
	return null;
};
Portal.defaultProps = {
	id: 'portal-root',
};

export default Portal;
