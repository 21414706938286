import systemStore from "../store/systemStore";
import {LocStore} from "./localStorage.util";
import SOLO from "../constants/solo.constant";
import paymentStore from "../store/paymentStore";

export const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);
}

export const getBankList = async () => {
    if(!LocStore('bankList')) {
        // 보증금 환급 받을 계좌
        return await fetch(`${process.env.REACT_APP_API_URL}/payment/deposit/iamport/bank`, {
            method: "GET", // *GET, POST, PUT, DELETE 등
            headers: {
                "Content-Type": "application/json",
            },
        }).then(res => {
            try {
                if(res.statusCode === 200) LocStore('bankList', res?.data)
            } catch (e) {
                // 에러 모달 처리 필요
                console.log(e)
            }
        })
    }
}

export const createErrorMsgModal = (statusCode, title, body) => {
    const message = {
        statusCode: statusCode,
        name: title,
        message: body
    }
    systemStore.showErrorModal(message);
}

export const stepChange = (level = 'step:1') => {
    const hash = LocStore(SOLO.HASH_KEY);
    LocStore(`${SOLO.RESERV_STEP}${hash}`,level);
    paymentStore.changePaymentStep(level);
}

export const stepReset = () => {
    const hash = LocStore(SOLO.HASH_KEY);
    LocStore(`${SOLO.RESERV_STEP}${hash}`, 'step:1');
    paymentStore.changePaymentStep('step:1');
}