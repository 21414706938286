import React, {createContext, useEffect, useMemo, useState} from 'react';
import { io } from 'socket.io-client';
import SOLO from "../constants/solo.constant";
const contentContext = createContext();
const serverUrl = `${process.env?.REACT_APP_SOCKET_URL || 'http://localhost:4000'}`;

export const ContentContextProvider = ({ children }) => {
  return (
      <contentContext.Provider value={null}>
        {children}
      </contentContext.Provider>
  );
};

export default contentContext;
