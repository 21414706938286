import React, {useEffect, useState} from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {observer} from "mobx-react";
import systemStore from "../../store/systemStore";

const SplashScreen = observer(() => {

    const [animationState, setAnimationState] = useState('initial');
    const [state, setState] = useState(true);
    // 애니메이션 variants
    const logoVariants = {
        initial: { transform: "translate(-50%, -50%) scale(1)", opacity: 0.4 },
        animate: {transform: "translate(-50%, -50%) scale(0.45)", opacity: 0.8},
        end: { transform: "translate(-50%, -50%) scale(0.4)", opacity: 1 },
    };

    const handleAnimationComplete = () => {
        // 애니메이션이 완료되면 splashScreen 값을 false 로 설정합니다.
        console.log('handleAnimationComplete');
        setTimeout(() => {
            setAnimationState('end');
        }, 150);

    }

    const handleAnimation = () => {
        console.log('systemStore.splashScreen: ', systemStore.splashScreen);
        console.log('animationState: ', animationState);
        console.log('state: ', state);
        if(systemStore.splashScreen && animationState === 'end') {
            setState(false);
        }
    }

    useEffect(() => {
        // systemStore.splashScreen 값이 변경될 때마다 호출됩니다.
        handleAnimation();
    }, [systemStore.splashScreen,animationState]); // systemStore.splashScreen 값에 따라 useEffect 를 재실행합니다.


    return (
        <AnimatePresence mode="wait" >
            {state && (
            <motion.div
                initial="initial"
                exit="initial"
                style={{
                    position: 'relative',
                    backgroundColor: "white",
                    height: '100vh',
                }}
            >
                <motion.svg
                    style={{
                        zIndex: '1001',
                        position:"absolute",
                        left: '50%',
                        top: '50%',
                        transform: "translate(-50%,-50%)",
                    }}
                    initial="initial"
                    animate="animate"
                    variants={logoVariants}
                    onAnimationComplete={handleAnimationComplete} // 애니메이션 완료 콜백
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 563 563'
                    transition={{ duration: 0.7 }}
                >
                        <rect
                            width="563"
                            height="563"
                            fill='none'
                        />
                        <path
                            d="M481.683 145.483L95.7118 75.019C90.1756 74.0341 84.9676 75.2279 80.6849 77.8095C78.1629 81.0775 80.655 84.9723 83.3411 86.7033C89.2802 90.5235 366.332 235.72 366.332 235.72L482.981 187.774C485.608 186.699 487.533 184.416 488.159 181.655L492.651 162.122C494.397 154.467 489.413 146.901 481.683 145.483Z"
                            fill="#FF008E"/>
                        <path
                            d="M80.6858 77.8105C72.6426 82.6604 67.942 92.4943 70.8817 102.642L180.144 480.823C182.323 488.359 189.217 493.552 197.067 493.552H222.793C226.016 493.552 228.986 491.776 230.508 488.941L366.333 235.721C366.333 235.721 89.2812 90.5395 83.342 86.7044C80.656 84.9734 78.1639 81.0786 80.6858 77.8105Z"
                            fill="#BA0267"/>

                </motion.svg>
            </motion.div>
            )}
        </AnimatePresence>
    );
});

export default SplashScreen;
