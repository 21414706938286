import React, { useEffect, useRef } from 'react';

const MatrixRain = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        const katakana = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789@#$%^&*()*&^%+-/~{[|`]}';
        const fontSize = 10;
        const columns = canvas.width / fontSize;
        const rainDrops = [];

        for (let x = 0; x < columns; x++) {
            rainDrops[x] = 1;
        }

        const drawMatrixRain = () => {
            context.fillStyle = 'rgba(0, 0, 0, 0.05)';
            context.fillRect(0, 0, canvas.width, canvas.height);

            context.fillStyle = '#0F0';
            context.font = `${fontSize}px monospace`;

            for (let i = 0; i < rainDrops.length; i++) {
                const text = katakana.charAt(Math.floor(Math.random() * katakana.length));
                context.fillText(text, i * fontSize, rainDrops[i] * fontSize);

                if (rainDrops[i] * fontSize > canvas.height && Math.random() > 0.975) {
                    rainDrops[i] = 0;
                }
                rainDrops[i]++;
            }
        };

        const drawMessage = () => {
            const message = "당신의 사이트를 생성중입니다.";
            context.font = `bold 24px monospace`;

            let messageX = (canvas.width - context.measureText(message).width) / 2;
            const messageY = (canvas.height / 2);

            for (let i = 0; i < message.length; i++) {
                const opacity = Math.random();
                const rotate = Math.random() * 20 - 10; // Rotate between -10 and 10 degrees
                const dx = Math.random() * 5 - 2.5;
                const dy = Math.random() * 5 - 2.5;

                context.save();
                context.translate(messageX + dx + context.measureText(message[i]).width / 2, messageY + dy);
                context.rotate((rotate * Math.PI) / 180);
                context.fillStyle = `rgba(0, 255, 0, ${opacity})`;
                context.fillText(message[i], 0, 0);
                context.restore();

                messageX += context.measureText(message[i]).width;
            }
        };

        const draw = () => {
            drawMatrixRain();
            drawMessage();
        };

        const intervalId = setInterval(draw, 100);

        return () => clearInterval(intervalId);
    }, []);

    return <canvas ref={canvasRef}></canvas>;
};

export default MatrixRain;
