import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter as Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

import App from './App';

import './i18n';
import './styles/index.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import { ThemeContextProvider } from "./context/themeContext";
import { SoloProvider } from "./context/soloContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<ThemeContextProvider>
		<Router>
			<SoloProvider>
				<App />
			</SoloProvider>
		</Router>
	</ThemeContextProvider>,
);

reportWebVitals();
