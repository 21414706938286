import {jwtDecode} from "jwt-decode";

export const tokenStorage = () => {
    const value1 = window.localStorage.getItem(`${process.env.REACT_APP_TOKEN_KEY}`);
    const value2 = window.localStorage.getItem(`${process.env.REACT_APP_TOKEN_KEY}-refreshToken`);
    if (value1 && value2) {
        return {
            token: value1,
            refreshToken: value2,
        };
    }
    return {
        token: null,
        refreshToken: null,
    };
}