import { makeAutoObservable } from 'mobx';

class SystemStore {

    showError = false;
    blocking = false;
    errorModal = null;
    errorStatus = null;
    splashScreen = true;


    showErrorModal = (error) => {
        const {statusCode, name, message} = error
        if(name === 'ERR_NETWORK' || !!statusCode) {
            // Page Not Found
            this.showError = true;
            this.errorStatus = statusCode;
            this.errorModal = {
                title: name,
                content: message,
            }
        }
    }

    loadFinish = () => {
        this.splashScreen = true;
    }

    showErrorCustomModal = (message) => {
        this.showError = true;
        this.blocking = true;
        this.errorModal = {
            title: '알림',
            content: message,
        }
    }

    hideErrorModal = () => {
        this.showError = false;
        this.errorModal = null;
    }

    constructor() {
        makeAutoObservable(this);
    }
}

export default new SystemStore();