import Modal from "../../components/ui/Modal";
import React from "react";
import {observer} from "mobx-react";
import systemStore from "../../store/systemStore";
import Alert from "../../components/ui/Alert";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import {LocStore, RmStore} from "../../utils/localStorage.util";
import SOLO from "../../constants/solo.constant";
import {stepChange} from "../../utils/common.util";

const ErrorModal = observer(() => {
    const {t} = useTranslation()
    const location = useLocation();
    const hash = LocStore(SOLO.HASH_KEY);

    const closeModal = () => {
        if(!systemStore.blocking)systemStore.hideErrorModal();
        switch (systemStore.errorStatus) {
            case 404:
            case 601:
                // 리소스 Not Found 에러
                // 유효하지 않은 토큰 요청
                window.location.href = '/#/error'
                break;
            case 500:
            case 600:
                // 서버 에러 500과 동일(통신 에러)
                window.location.reload();
                break;
            case 602:
                // 실패(포기, 취소, 실패)
            case 603:
                // 이미 결제가 이루어진 건
                break;
            case 604:
                // 잘못된 계좌 정보
                break;
            case 605:
                // 네이버 예약 요청 실패
                break;
            case 606:
                // 소켓 연결 실패
                window.location.reload();
                break;
            case 607:
                // API 요청 실패
                window.location.reload();
                break;
            case 608:
                RmStore('linkUrl');
                RmStore(`${SOLO.RESOC_ID}${hash}`);
                stepChange('step:1');
                window.location.reload();
                break;
            case 609:
                // 결제 요청 후 리다이렉트 URL로 접근 시 블락 처리
                RmStore(Object.values(SOLO));
                window.location.href = '/#/error';
                break;
            default:
        }
    }

    return (
        <Modal
            isOpen={systemStore.showError && location.pathname !== '/error'}
            setIsOpen={closeModal}
            isAnimation={false}
            isScrollable={false}
        >
            {
                !!(systemStore.showError) && <Alert
                    className='border-transparent'
                    title={t(systemStore.errorModal?.title)}
                    color='red'
                    icon='HeroExclamationCircle'
                    variant='solid'
                >
                    <span className='text-light-primaryText dark:text-dark-primaryText'>{t(systemStore.errorModal?.content)}</span>
                </Alert>
            }
        </Modal>
    );
});

export default ErrorModal;