

const SOLO_STORAGE = {
	DEVICE_TOKEN: 'deviceToken:',
	CLIENT_AUTH: 'clientAuth:',
	HASH_KEY: 'hashKey',
	RESERVATION: 'reservation:',
	LANDING_URL: 'landingUrl:',
	METHOD: 'method:',
	RESERV_STEP: 'reservationStep:',
	METHOD_LIST: 'methodList:',
	RESOC_ID: 'resocId:'
};

export default SOLO_STORAGE;
