import { useEffect, useState } from 'react';
import themeConfig from '../config/theme.config';

const useDocumentTitle = ({
	title = themeConfig.projectTitle,

	name = themeConfig.projectName,
}) => {
	const [documentTitle, setDocumentTitle] = useState(`${title} | ${name}`);

	useEffect(() => {
		document.title = documentTitle;
	}, [documentTitle, title, name]);

	return [documentTitle, setDocumentTitle];
};

export default useDocumentTitle;
