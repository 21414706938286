import React, { forwardRef, memo } from 'react';
import classNames from 'classnames';
import pascalcase from 'pascalcase';
import * as HeroIcon from './heroicons';
import { textColor } from '../../utils/textColor.util';


const RefWrapper = forwardRef(({ children }, ref) => {
	if (ref) {
		return (
			<span ref={ref} data-only-ref='true'>
				{children}
			</span>
		);
	}
	return children;
});
RefWrapper.displayName = 'RefWrapper';

const Icon = forwardRef((props, ref) => {
	const { icon, className, color, colorIntensity, size, ...rest } = props;
	const IconName = pascalcase(icon);
	const HeroWrapper = HeroIcon[IconName];

	const CLASS_NAMES = classNames(
		'svg-icon',
		{ [`${size}`]: typeof size !== 'undefined' },
		textColor(color, colorIntensity),
		className,
	);
	if (typeof HeroWrapper === 'function') {
		return (
			<RefWrapper ref={ref}>
				<HeroWrapper
					data-component-name='Icon-C'
					data-name={`Hero--${icon}`}
					className={CLASS_NAMES}
					
					{...rest}
				/>
			</RefWrapper>
		);
	}
	return null;
});
Icon.defaultProps = {
	className: undefined,
	color: undefined,
	colorIntensity: undefined,
	size: undefined,
};
Icon.displayName = 'Icon';

export default memo(Icon);
